import './HomePage.css';
import {Link} from "react-router-dom";

function HomePage() {
  return (
    <div className="HomePage">
        <div className="header">
            Shaun Wickett
        </div>
        <div className="separator"/>
        <div className="links">
            <Link to="/life" className="link">life</Link>
        </div>
    </div>
  );
}

export default HomePage;
