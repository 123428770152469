import './GameOfLife.css';
import {BiSkipNext, BsFillPauseBtnFill, BsFillPlayBtnFill, MdCancel, MdCancelPresentation} from "react-icons/all";
import {useEffect, useState} from "react";

const _isPrime = {}

const isPrime = num => {
    const cached = _isPrime[num]
    if (typeof cached == "boolean") {
        return cached
    }
    for(let i = 2, s = Math.sqrt(num); i <= s; i++) {
        if(num % i === 0) {
            _isPrime[num] = false;
            return false;
        }
    }
    const p = num > 1;
    _isPrime[num] = p
    return p;
}

const tile = (alive = false, energy = 0, age = 0) => {
    return { alive, energy, age }
}

const generateGameBoard = (size) => {
    return Array.from(Array(size).keys(), rowIndex => Array.from(Array(size).keys(), colIndex => tile()));
}

const transitionTile = (board, tileRow, tileCol) => {
    let neighborsAlive = 0;
    let neighborsEnergy = 0;

    for (let neighborRow = tileRow - 1; neighborRow <= tileRow + 1; neighborRow++) {
        if (neighborRow < 0 || neighborRow >= board.length) {
            continue;
        }
        for (let neighborCol = tileCol - 1; neighborCol <= tileCol + 1; neighborCol++) {
            if (neighborCol < 0 || neighborCol >= board[neighborRow].length) {
                continue;
            }
            if (neighborRow === tileRow && neighborCol === tileCol) {
                continue;
            }
            const neighborState = board[neighborRow][neighborCol];

            if (neighborState.alive) {
                neighborsAlive++;
                neighborsEnergy+=neighborState.energy;
            }
        }
    }

    const tileState = board[tileRow][tileCol];
    const primeEnergy = isPrime(neighborsEnergy);

    if (tileState.alive) {
        const stayAlive = neighborsAlive === 2 || neighborsAlive === 3;

        if (stayAlive) {
            return tile(
                true,
                primeEnergy ? tileState.energy + neighborsEnergy : tileState.energy,
                tileState.age + 1
            );
        } else {
            return tile(false)
        }
    } else {
        const becomeAlive = neighborsAlive === 3;

        if (becomeAlive) {
            return tile(
                true,
                1,
                1
            )
        } else {
            return tile(false)
        }
    }
}

const generateNextBoard = (board) => {
    const newBoard = generateGameBoard(board.length)
    for (let row = 0; row < board.length; row++) {
        for (let col = 0; col < board[row].length; col++) {
            newBoard[row][col] = transitionTile(board, row, col)
        }
    }
    return newBoard;
}

const GameOfLife = () => {
    const [boardSize, setBoardSize] = useState(150);
    const [board, setBoard] = useState(generateGameBoard(boardSize));
    const [isDrawing, setIsDrawing] = useState(false);
    const [isRunning, setIsRunning] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            if (isRunning) {
                setBoard(board => generateNextBoard(board));
            }
        }, 200);
        return () => clearInterval(interval);
    }, [isRunning]);

    const divs = []
    for (let i = 0; i < boardSize; i++) {
        for (let j = 0; j < boardSize; j++) {
            const tileState = board[i][j];
            divs.push(
                <div
                    key={`${i}/${j}`}
                    data-row={i}
                    data-col={j}
                    className={`life-tile ${tileState.alive ? 'alive' : ''}`}
                    onPointerDown={(e) => {
                        e.preventDefault();
                        setIsRunning(false)
                        setIsDrawing(true)
                        if (!board[i][j].alive) {
                            board[i][j] = tile(true, 1, 1)
                            setBoard(board)
                        }
                    }}
                    onPointerMove={(e) => {
                        if (isDrawing) {
                            e.preventDefault()
                            if (!board[i][j].alive) {
                                board[i][j] = tile(true, 1, 1)
                                setBoard(board)
                            }
                        }
                    }}
                >
                    {tileState.age}
                </div>
            )
        }
    }
    return (
        <div className="life-game" onPointerUp={(e) => {
            e.preventDefault()
            setIsDrawing(false)
        }}>
            <header>
                {
                    !isRunning &&
                    <button onClick={(e) => {
                        e.preventDefault()
                        setIsRunning(true)
                    }}>
                        <BsFillPlayBtnFill/>
                    </button>
                }
                {
                    isRunning &&
                    <button onClick={(e) => {
                        e.preventDefault()
                        setIsRunning(false)
                    }
                    }>
                        <BsFillPauseBtnFill/>
                    </button>
                }
                <button onClick={(e) => {
                    setBoard(board => generateNextBoard(board))
                }}>
                    <BiSkipNext/>
                </button>
                <button onClick={(e) => {
                    setIsRunning(false);
                    setBoard(generateGameBoard(boardSize))
                }}>
                    <MdCancel/>
                </button>
            </header>
            <div className="life-board">
                {divs}
            </div>
        </div>

    );
}

export default GameOfLife;